<template>
	<div>
		<b-modal
			id="modal-action-promotion"
			cancel-variant="outline-secondary"
			ok-title="Submit"
			cancel-title="Close"
			ok-variant="primary"
			centered
			:title="title"
			scrollable
			@ok="confirmSubmit"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationForm)"
					@reset.prevent="resetForm"
				>
					<b-row>
						<!-- Transaction Id -->
						<b-col
							cols="12"
						>
							<b-form-group
								label="Transaction Id"
								label-for="transactionId"
							>
								<b-form-input
									id="name"
									v-model="localTransfer.reference"
									trim
									disabled
								/>
							</b-form-group>
						</b-col>

						<!-- Username -->
						<b-col
							cols="12"
						>
							<b-form-group
								label="Username"
								label-for="transactionId"
							>
								<b-form-input
									id="name"
									v-model="localTransfer.username"
									trim
									disabled
								/>
							</b-form-group>
						</b-col>

						<!-- Currency -->
						<b-col
							cols="12"
						>
							<b-form-group
								label="currency"
								label-for="currency"
							>
								<b-form-input
									id="currency"
									v-model="localTransfer.currency"
									disabled
								/>
							</b-form-group>
						</b-col>

						<!-- Amount -->
						<b-col
							cols="12"
						>
							<b-form-group
								label="Amount"
								label-for="amount"
							>
								<b-form-input
									id="amount"
									v-model="localTransfer.amount"
									disabled
								/>
							</b-form-group>
						</b-col>
						<!-- Note -->
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="note"
							>
								<label for="remark">Note</label>
								<validation-provider
									#default="{ errors }"
									name="note"
									rules="required"
								>
									<b-form-textarea
										id="note"
										v-model="localTransfer.reason"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormFile, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { numberFormat, resolveCurrency } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge, BFormFile,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		promotionContent: {
			type: [Object, null],
			default: null,
		},
		type: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			required,
			title: '',
			localTransfer: {
				id: null,
				reference: null,
				username: null,
				currency: null,
				amount: null,
				status: this.type,
				reason: null,
			},
		}
	},
	watch: {
		localTransferData: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.localTransfer = {
						id: newVal.id,
						reference: newVal.ref,
						username: newVal.user ? newVal.user.username : 'n/a',
						currency: newVal.user ? newVal.user.currency : 'VND',
						amount: newVal.amount / 1000,
						reviewAmount: numberFormat(newVal.amount / 1000),
						status: 2,
						reason: '',
					}
				}
			}
		},
		type: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.title = (newVal == 2) ? 'Approve Transfer Transaction' : 'Reject Transfer Transaction'
					this.localTransfer.status = newVal
				}
			}
		},
	},
	methods: {
		validationForm() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					/* store
						.dispatch('payment-local-transfer/updateLocalTransferStatus', this.localTransfer)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$emit('refetch-data')
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											this.$bvModal.hide('modal-approve-local-transfer')
											this.resetForm()
										}, '500')
									})
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'Error Proccess',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}) */
				}
			})
		},
		confirmSubmit(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		},
		resetForm() {
			this.$emit('update:localTransferData', null)
			this.$emit('update:type', null)
		},
	},
	setup() {

		return {
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: 100% !important;
}
</style>
